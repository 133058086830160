
import { storageService } from '../services';
import { config } from '../utils/config';
import { WebStorageNames } from '../utils/Constants';

const getToken = () => {
  const token = storageService.getItem(WebStorageNames.Token);
  return token ? `Bearer ${token}` : null;
};

export async function fetcher(path, options) {
  const {
    method = 'GET',
    body,
    basePath = config.PEOPLEMANAGEMENT_API_URL, // Default to PUPIL_API_URL or change any other on impilcitly
    schema = null,
    headers = {},
    config: customConfig,
  } = options;

  const token = getToken();
  const authHeaders = token ? { Authorization: token } : {};


  const fullUrl = `${basePath}${path}`;

  try {
    const response = await fetch(fullUrl, {
      ...customConfig,
      headers: {
        'Content-Type': body instanceof FormData ? undefined : 'application/json',
        ...authHeaders,
        ...headers,
      },
      credentials: 'include',
      method,
      ...(body && {
        body: body instanceof FormData ? body : JSON.stringify(body),
      }),
    });

    const contentType = response.headers.get('content-type');
    let data;

  
    if (contentType && contentType.includes('application/json')) {
      data = await response.json();
    } else if (contentType && contentType.includes('application/octet-stream')) {
      data = await response.blob();
    } else {
      data = await response.text();
    }


    if (!response.ok) {
      const UNAUTHORIZED = 401;
      const FORBIDDEN = 403;

      if (response.status === UNAUTHORIZED || response.status === FORBIDDEN) {
        console.error(`Unauthorized or forbidden: ${response.status}`);
        window.location = '/login'; 
      }

      const errorMessage = typeof data === 'string' ? data : 'Request failed';
      throw new Error(errorMessage || `Request failed with status: ${response.status}`);
    }

    if (data && schema) {
      try {
        schema.validateSync(data, { abortEarly: false });
        return data;
      } catch (validationError) {
        if (validationError.name === 'ValidationError') {
          throw new Error(`Validation Error: ${validationError.errors.join(', ')}`);
        }
        throw validationError;
      }
    }

    return data || null;
  } catch (err) {
    if (err instanceof Error || typeof err === 'string') {
      throw err;
    }
    throw new Error(err.message || 'Something went wrong during fetching!');
  }
}
