import { toast } from 'react-toastify';

/**
 * Displays toast notifications for API responses.
 *
 * @param {string} type - The type of notification ('success' or 'error').
 * @param {object} messageDetails - The message details, can include error or success messages.
 * @param {string} defaultMessage - Fallback message if none is provided.
 * @param {object} toastOptions - Additional options for toast configuration.
 */
export const HandleApiToast = (type, messageDetails = {}, defaultMessage = 'An unexpected event occurred.', toastOptions = {}) => {
  console[type === 'success' ? 'log' : 'error'](`API ${type}:`, messageDetails);


  const message =
    messageDetails?.response?.data?.title || 
    messageDetails?.response?.data?.detail || 
    messageDetails?.message ||
    messageDetails?.title ||
    defaultMessage; 


  const toastMethod = type === 'success' ? toast.success : toast.error;

  toastMethod(message, {
    autoClose: 8000,
    position: 'top-center',
    ...toastOptions,
  });
};
