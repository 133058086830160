

import { HandleApiToast } from '../../../components/common/HandleApiToast';
import { useQuery } from '@tanstack/react-query';
import { fetchParentsByGroupId } from '../ParentService';

export const useFetchParentByGroupId = (groupId,enabled = true) => {
    
  return useQuery(
    ['parentsByGroupId', groupId], 
    () => fetchParentsByGroupId(groupId),
    {
      enabled:!!enabled && !!groupId,
      onSuccess: (data) => {
       
      },
      onError: (error) => {
        HandleApiToast('error', error, 'Failed to fetch parents.');
      },
    }
  );
};
