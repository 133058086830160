import { fetcher } from "../fetcher";



export const fetchParentsByGroupId = async (groupId) => {
  const path = `/student/GetParentsByGroupId?groupId=${groupId}`;

  return await fetcher(path, {
    method: 'GET',
  });
};

export const fetchParentsBySchoolId = async (schoolId) => {
  const path = `/student/GetParentsBySchoolId?schoolId=${schoolId}`;

  return await fetcher(path, {
    method: 'GET',
  });
};

