import { useQuery } from '@tanstack/react-query';

import { HandleApiToast } from '../../../components/common/HandleApiToast';
import { fetchClassesSettings } from '../SyncSettingsService';


export const useFetchClassesSettings = (id, isGroup) => {
  return useQuery(
    ['classesSettings', id, isGroup],
    () => fetchClassesSettings(id, isGroup),
    {
      enabled: !!id,
      onError: (error) => HandleApiToast('error', error, 'Failed to fetch classes settings.'),
    }
  );
};

