import { fetcher } from "../fetcher";


export const addSyncSubjectSettings = async (syncSubjectSetting) => {
  const path = `/SubjectSync/AddSyncSubjectSettings`;

  return await fetcher(path, {
    method: 'POST',
    body: syncSubjectSetting,
  });
};


export const fetchStudentSettings = async (id, isGroup) => {
  const path = isGroup
    ? `/SyncSetting/GetStudentSettingsByGroupId?groupId=${id}`
    : `/SyncSetting/GetStudentSettingsBySchoolId?schoolId=${id}`;
  return await fetcher(path, { method: 'GET' });
};

export const saveStudentSettings = async (data, isGroup) => {
  const path = isGroup
    ? `/SyncSetting/SyncStudentSettingByGroupId`
    : `/SyncSetting/SyncStudentSettingBySchoolId`;
  return await fetcher(path, {
    method: 'POST',
    body: data,
    //headers: { 'Content-Type': 'application/json' },
  });
};

export const fetchAchievementSettings = async (id, isGroup) => {
  const path = isGroup
    ? `/SyncSetting/GetAchievementSettingsByGroupId?groupId=${id}`
    : `/SyncSetting/GetAchievementSettingsBySchoolId?schoolId=${id}`;
  return await fetcher(path, { method: 'GET' });
};

export const saveAchievementSettings = async (data, isGroup) => {
  const path = isGroup
    ? `/SyncSetting/SyncAchievementSettingByGroupId`
    : `/SyncSetting/SyncAchievementSettingBySchoolId`;
  return await fetcher(path, {
    method: 'POST',
    body: data,
  });
};

export const saveAttendanceSettingsSchool = async (data) => {
  const path = `/SyncSetting/SyncAttendanceSettingBySchoolId`;
  return await fetcher(path, {
    method: 'POST',
    body: data,
  });
};

export const saveAttendanceSettingsByGroup = async (data) => {
  const path = `/SyncSetting/SyncAttendanceSettingByGroupId`;
  return await fetcher(path, {
    method: 'POST',
    body: data,
  });
};

export const fetchAttendanceSettingsSchoolId = async (schoolId) => {
  const path = `/SyncSetting/GetAttendanceSettingsBySchoolId?schoolId=${schoolId}`;
  return await fetcher(path, {
    method: 'GET',
  });
};

export const fetchAttendanceSettingsByGroupId = async (groupId) => {
  const path = `/SyncSetting/GetAttendanceSettingsByGroupId?groupId=${groupId}`;
  return await fetcher(path, {
    method: 'GET',
  });
};

export const fetchBehaviourSettings = async (id, isGroup) => {
  const path = isGroup
    ? `/SyncSetting/GetBehaviourSettingsByGroupId?groupId=${id}`
    : `/SyncSetting/GetBehaviourSettingsBySchoolId?schoolId=${id}`;
  return await fetcher(path, { method: 'GET' });
};

export const saveBehaviourSettings = async (data, isGroup) => {
  const path = isGroup
    ? `/SyncSetting/SyncBehaviourSettingByGroupId`
    : `/SyncSetting/SyncBehaviourSettingBySchoolId`;
  return await fetcher(path, {
    method: 'POST',
    body: data,
  });
};

export const fetchClassesSettings = async (id, isGroup) => {
  const path = isGroup
    ? `/SyncSetting/GetClassesSettingsByGroupId?groupId=${id}`
    : `/SyncSetting/GetClassesSettingsBySchoolId?schoolId=${id}`;
  return await fetcher(path, { method: 'GET' });
};

export const saveClassesSettings = async (data, isGroup) => {
  const path = isGroup
    ? `/SyncSetting/SyncClassesSettingByGroupId`
    : `/SyncSetting/SyncClassesSettingBySchoolId`;
  return await fetcher(path, {
    method: 'POST',
    body: data,
  });
};

export const fetchParentSettings = async (id, isGroup) => {
  const path = isGroup
    ? `/SyncSetting/GetParentSettingsByGroupId?groupId=${id}`
    : `/SyncSetting/GetParentSettingsBySchoolId?schoolId=${id}`;
  return await fetcher(path, { method: 'GET' });
};

export const saveParentSettings = async (data, isGroup) => {
  const path = isGroup
    ? `/SyncSetting/SyncParentSettingByGroupId`
    : `/SyncSetting/SyncParentSettingBySchoolId`;
  return await fetcher(path, {
    method: 'POST',
    body: data,
  });
};

export const fetchSubjectSettings = async (id, isGroup) => {
  const path = isGroup
    ? `/SyncSetting/GetSubjectSettingsByGroupId?groupId=${id}`
    : `/SyncSetting/GetSubjectSettingsBySchoolId?schoolId=${id}`;
  return await fetcher(path, { method: 'GET' });
};

export const saveSubjectSettings = async (data, isGroup) => {
  const path = isGroup
    ? `/SyncSetting/SyncSubjectSettingByGroupId`
    : `/SyncSetting/SyncSubjectSettingBySchoolId`;
  return await fetcher(path, {
    method: 'POST',
    body: data,
  });
};

export const fetchStaffSettings = async (id, isGroup) => {
  const path = isGroup
    ? `/Staff/GetStaffSettingsByGroupId?groupId=${id}`
    : `/Staff/GetStaffSettingsBySchoolId?schoolId=${id}`;
  return await fetcher(path, { method: 'GET' });
};

export const saveStaffSettings = async (data, isGroup) => {
  const path = isGroup
    ? `/Staff/SyncStaffSettingByGroupId`
    : `/Staff/SyncStaffSettingBySchoolId`;
  return await fetcher(path, {
    method: 'POST',
    body: data,
  });
};

