
import { HandleApiToast } from '../../../components/common/HandleApiToast';
import { useQuery } from '@tanstack/react-query';
import { fetchParentsBySchoolId } from '../ParentService';

export const useFetchParentsBySchoolId = (schoolId,enabled = true) => {
  return useQuery(
    ['parentsbySchoolId', schoolId], 
    () => fetchParentsBySchoolId(schoolId),
    {
      enabled: !!enabled && !!schoolId,
      onSuccess: (data) => {
       
      },
      onError: (error) => {
        HandleApiToast('error', error, 'Failed to fetch parents.');
      },
    }
  );
};
