import { fetcher } from "../fetcher";



export const fetchStudentsWithSchoolsByGroupId = async (groupId) => {
  const path = `/student/GetStudentsWithSchoolsByGroupId?groupId=${groupId}`;

  return await fetcher(path, {
    method: 'GET',
  });
};

export const fetchStudentsWithSchoolsBySchoolId = async (schoolId) => {
  const path = `/student/GetStudentsWithSchoolsBySchoolId?schoolId=${schoolId}`;

  return await fetcher(path, {
    method: 'GET',
  });
};


export const updateStudentById = async (studentModel) => {
  const path = `/student/UpdateStudent`;
  return await fetcher(path, {
    method: 'POST',
    body: studentModel,
  });
};

export const deleteStudentById = async (studentId) => {
    const path = `/student/${studentId}`;
    return await fetcher(path, {
      method: 'DELETE',
    });
  };
  
