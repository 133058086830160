import { useMutation } from "@tanstack/react-query";
import { HandleApiToast } from "../../../components/common/HandleApiToast";
import { saveAttendanceSettingsByGroup, saveAttendanceSettingsSchool } from "../SyncSettingsService";

export const useSaveAttendanceSettings = (isGroupEnabled) => {
    return useMutation(
      (data) =>
        isGroupEnabled
          ? saveAttendanceSettingsByGroup(data)
          : saveAttendanceSettingsSchool(data),
      {
        onSuccess: () => {
          // Handle success if needed
        },
        onError: (error) => {
          HandleApiToast(
            'error',
            error,
            'Failed to save attendance sync settings.'
          );
        },
      }
    );
  };