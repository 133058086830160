import React from 'react';
import { CircularProgress, Box } from '@mui/material';

const LoaderWrapper = ({ isLoading, children }) => {
  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  return <>{children}</>;
};

export default LoaderWrapper;
