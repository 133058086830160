import { LeftMenu, MainHeader } from '../../components';
import { ActionPlanMainWrapper } from '../../components/common/ActionPlanStyle';
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
// import AddStaffMember from '../staff/AddStaffMember';
// import EditStaffMember from '../staff/EditStaffMember';
import AddStaffAccess from './AddStaffLevelAccess';
import EditStaffAccess from './EditStaffLevelAccess';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import {
  getSelectedRow
} from '../../utils/CommonFunctions';
import {
  Button,
  Paper,
  Tab,
  Tabs,
  alpha,
  styled,
  Grid,
  Container,
} from '@mui/material';
import { Stack } from '@mui/system';
import { HeaderDataGrid } from '../../resources/styling/appStyle';
//import { Link as MuiLink } from '@material-ui/core';
import clsx from 'clsx';

import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { getStudentsListAction } from '../../action/getStudentsListAction';
import React, { useState, useEffect } from 'react';
import { set, wrap } from 'lodash';
import {
  getQueryStringVal,
  getStaffJobRole,
  getStaffJobTitle,
} from '../../utils/CommonFunctions';
import SchoolStaff from '../staff/SchoolStaff';
import MatStaff from '../mat/matStaff/MatStaff';
import ResponsiveAppBar from '../../components/common/ResponsiveAppBar';
import ResponsiveAppBarClass from '../../components/common/ResponsiveAppBar';
import { routesObj } from '../../routes';

import { QueryStringTokens, WebStorageNames } from '../../utils';
import { schoolService,matStaffService,schoolStaffService, storageService } from '../../services';
import { PeopleManagement } from '../pupil/PeopleMgtStyle';
import { WrapText } from '@mui/icons-material';



const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  height: 470,  
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',    
  },
  '& .MuiDataGrid-cell': {    
    'white-space': 'normal !Important',
    'overflow-wrap': 'break-word',
  },
}));

const AccessManagement = () => {
  const [staffId, setStaffId] = useState(false);
  const [staffAccess, setStaffAccess] = useState([]);
 const [schoolId, setSchoolId] = useState('');
   const [matSchools, setMatSchools] = useState('');
  const [isMatAccount, setIsMatAccount] = useState(true);
  const [refreshKey, setRefreshKey] = useState(0);

  const [tabValue, setTabValue] = useState(0);
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
    queryOptions: { field: '', sort: '' },
    value: null,
    filterOptions: { field: '', value: 0 },
  });
    const [isShowAddStaffAccess, setIsShowAddStaffAccess] = useState(false);
    const [isShowEditStaffAccess, setIsShowEditStaffAccess] = useState(false);

  useEffect(() => {
    const data = getStaffAccessList();
    console.log('final data - '+ data)
    const staffAccessList = data;    
    setStaffAccess(staffAccessList);
  }, [refreshKey]);

  const getMatSchools = () => {
      const matId = storageService.getItem(WebStorageNames.MatGroupId);
      matStaffService
        .matSchools(matId)
        .then((response) => {
          const { data, success } = response;
          if (success) {
            setMatSchools(data);
          } else {
          }
        })
        .catch((error) => {});
    };

  const getStaff = () => {
      const id = storageService.getItem(WebStorageNames.SchoolId);
     
      //Set PageState
      if (pageState !== undefined) {
        setPageState((old) => ({
          ...old,
          isLoading: true,
          data: old.data,
          total: old.total,
          queryOptions: old.queryOptions,
          filterOptions: old.filterOptions,
          value: old.value,
        }));
      }
  
      let filter = null;
      if (pageState?.filterOptions?.field !== '') {
        filter = [
          {
            Field: pageState.filterOptions.field,
            Value: pageState.filterOptions.value,
          },
        ];
      }
  
      let order = null;
      if (pageState?.queryOptions?.field !== '') {
        order = [
          {
            Field: pageState.queryOptions.field,
            Sort: pageState.queryOptions.sort,
          },
        ];
      }
      //Get Staff Of School
      const model = {
        Start: (pageState.page - 1) * pageState.pageSize,
        Length: pageState.pageSize,
        Order: order,
        Filter: filter,
        Search: {
          SchoolId: id,
          Value: pageState.value,
        },
        Columns: [],
      };
  
      schoolStaffService
        .getSchoolStaff(model)
        .then((response) => {
          const { data, success } = response;
          if (success) {
            const { IsJobRoleVerified } = data;
            //if (!IsJobRoleVerified) setIsUnVerifiedJobTitles(true);
            const dataRow = [...data.Data].map((item, index) => {
              // if (item.IsSyncedFromWonde) {
              //   setIsSyncedFromWonde(true);
              // }
              return {
                id: item.Id,
                firstName: item.FirstName,
                lastName: item.LastName,
                email: item.Email,
                accessType: item.AccessType,
                jobRole: item.JobRole,
                accessLevel: item.IsGranted ? item.AccessLevel : null,
                coreFilters: item.CoreFilters,
                filters: item.FilterValues,
              };
            });
            setPageState((old) => ({
              ...old,
              isLoading: false,
              data: dataRow,
              total: data.RecordsFiltered,
            }));
            // setRows(dataRow);
          } else {
            setPageState((old) => ({
              ...old,
              isLoading: false,
            }));
          }
        })
        .catch((error) => {
          setPageState((old) => ({
            ...old,
            isLoading: false,
            data: old.data,
            total: old.total,
          }));
          //loaderActions.dataLoading(false);
        });
    };

    const getMatStaff = () => {
      const matId = storageService.getItem(WebStorageNames.MatGroupId);
  
      //Set PageState
      if (pageState !== undefined) {
        setPageState((old) => ({
          ...old,
          isLoading: true,
          data: old.data,
          total: old.total,
          queryOptions: old.queryOptions,
          filterOptions: old.filterOptions,
          value: old.value,
        }));
      }
  
      let filter = null;
      if (pageState?.filterOptions?.field !== '') {
        filter = [
          {
            Field: pageState.filterOptions.field,
            Value: pageState.filterOptions.value,
          },
        ];
      }
  
      let order = null;
      if (pageState?.queryOptions?.field !== '') {
        order = [
          {
            Field: pageState.queryOptions.field,
            Sort: pageState.queryOptions.sort,
          },
        ];
      }
  
      //Get Groups Of School
      const model = {
        Start: (pageState.page - 1) * pageState.pageSize,
        Length: pageState.pageSize,
        Order: order,
        Filter: filter,
        Search: {
          MatGroupId: matId,
          Value: pageState.value,
        },
        Columns: [],
      };
  
      matStaffService
        .getStaff(model)
        .then((response) => {
          const { data, success } = response;
          if (success) {
            const { IsJobRoleVerified } = data;
            //if (!IsJobRoleVerified) setIsUnVerifiedJobTitles(true);
            const dataRow = [...data.Data].map((item, index) => {
              return {
                id: item.Id,
                firstName: item.FirstName,
                lastName: item.LastName,
                school: item.School.Name,
                email: item.Email,
                accessType: item.AccessType,
                jobRole: item.JobRole,
                accessLevel: item.IsGranted ? item.AccessLevel : null,
                coreFilters: item.CoreFilters,
                filters: item.FilterValues,
              };
            });
            setPageState((old) => ({
              ...old,
              isLoading: false,
              data: dataRow,
              total: data.RecordsFiltered,
            }));
          } else {
            setPageState((old) => ({
              ...old,
              isLoading: false,
            }));
          }
        })
        .catch((error) => {
          setPageState((old) => ({
            ...old,
            isLoading: false,
            data: old.data,
            total: old.total,
          }));
          //loaderActions.dataLoading(false);
        });
    };

  const columns = [        
    { field: 'Id', headerName: 'ID', hide: true },   
    { field: 'UserId', headerName: 'User ID', hide: true },  
    { field: 'StaffId', headerName: 'Staff ID', hide: true },      
    { field: 'FirstName', headerName: 'First Name', width: 100 },
    { field: 'LastName', headerName: 'Surname', width: 100 },
    { field: 'Email', headerName: 'Email', width: 190 },
    { field: 'Access', headerName: 'Access', width: 200, wrap:true },
    { field: 'Sites', headerName: 'Site(s)', width: 200, wrap:true },
    { field: 'Department', headerName: 'Department', width: 200, wrap:true },
    { field: 'Actions', headerName: 'Actions', width: 70, renderCell: (params) => {
      return (
        <div>      
          {/* <EditIcon  onClick={(e) => onEditClick(params)} style={{'cursor':'pointer'}}
          variant="contained" ></EditIcon>              */}
          <DeleteIcon onClick={(e) => onDeleteClick(params)} style={{'cursor':'pointer'}}
            variant="contained"></DeleteIcon>
        </div>
      );
    } }

    //{"Id":1,"UserId":"1b509c7b-d855-46d9-90b0-84e3864748cc","Access":"2,3,4","Department":"Science,Business,History",
    // "Sites":"1487","IsArchived":false,"StaffId":23432,"MatId":0,"IsActive":false,"AddedDate":"2025-01-08T15:20:29.421014",
    // "SchoolId":1487,"FirstName":"Amit","LastName":"Chakor","Email":"amit@welbee.co.uk"}
  ];

  const onDeleteClick = (e) => {
    //e.stopPropagation();
    let row = getSelectedRow(e);
    let userId = row.UserId;
    //alert(staffId); 
    fetch(
      `https://api.admin.welbee.co.uk/api/UserAccess/ArchiveUserAccessByUserId?userId=${userId}`,{
        method: 'DELETE',mode: 'no-cors','Access-Control-Allow-Origin': 'https://api.admin.welbee.co.uk'
      }      
    )
    .then((response) => {    
      toast.success('Record Deleted successfully.');                    
      return response.json();
    })
    .then((data) => {
      // setStaffAccess(data.data);
      setRefreshKey(oldKey => oldKey +1)
      console.log(data.message+'-'+staffId)
      return data.data;
    });
    //setStaffId(id);
    //setIsRemovePopupOpen(true);
  };

  const onEditClick = (e) => {
      //e.stopPropagation();
      let row = getSelectedRow(e);
      let userid = row.UserId;
      let staffId = row.StaffId;
      alert(userid);
      setStaffId(staffId);
      setIsShowEditStaffAccess(true);
      //setStaffId(id);
      //setIsRemovePopupOpen(true);
  };

const addStaffAccess = () => {    
    setIsShowAddStaffAccess(true);
  };

  const handleClose = () => {
    setIsShowAddStaffAccess(false);
    setIsShowEditStaffAccess(false);
  
  };

// async function getstudentsList(){
//   var schoolId = localStorage.getItem('schoolId');
//     // if (schoolId === null) schoolId = '1548';
//     schoolId = 1487;
//     fetch(
//       `https://welbee-peoplemanagement-api-393350227694.europe-west2.run.app/api/Student/GetStudentBySchoolId?schoolId=${schoolId}`
//     )
//       .then((response) => {
//         return response.json();
//       })
//       .then((data) => {
//         setStudents(data);
//         return data;
//       });
// }

  async function getStaffAccessList() {
    var isMat = localStorage.getItem('IsMat');    
    var matId = localStorage.getItem('MatGroupId');
    var schoolId = localStorage.getItem('schoolId');
    setSchoolId(schoolId);
    setIsMatAccount(isMat);

    if(isMat)
    {
      getMatSchools();
    }
    //  if (schoolId === 0) schoolId = '1487';
    // schoolId = 1487; // remove once tested
    if(!isMat)
    {
      fetch(
        `https://api.admin.welbee.co.uk/api/UserAccess/GetAllUserAccessRecordsBySchoolId?schoolId=${schoolId}`      
      )
      .then((response) => {    
        return response.json();
      })
      .then((data) => {
        setStaffAccess(data.data);
        console.log(data.data)
        return data.data;
      });
    }
    else{
      fetch(
        `https://api.admin.welbee.co.uk/api/UserAccess/GetAllUserAccessRecordsByMatId?matId=${matId}`      
      )
      .then((response) => {    
        return response.json();
      })
      .then((data) => {
        setStaffAccess(data.data);
        console.log(data.data)
        return data.data;
      });
    }
  }

  return (
    <>
      <ResponsiveAppBar />
      <Container maxWidth='lg'>
        <h3>Access Management</h3>
        <PeopleManagement>
          <Grid
            container
            alignItems='center'
            justifyContent='space-between'
            spacing={2}
          >
            {/* Tabs on the left side */}
            <Grid item lg={6} md={6} sm={6} xs={12} alignItems='center'>
              <div className='people-tabs'>
                <h3>Manage Access</h3>
              </div>
            </Grid>

            {/* Buttons on the right side */}
            <Grid item lg={6} md={6} sm={6} xs={12} textAlign='end'>
              <Stack direction='row' spacing={1} justifyContent='end'>
                {/* <Button
                  variant='contained'
                  className='filter-btn'
                  disableRipple
                  disableElevation
                  // onClick={postData}
                >
                  <svg
                    width='20'
                    height='20'
                    viewBox='0 0 20 20'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M17.7084 10H7.41258M3.77841 10H2.29175M3.77841 10C3.77841 9.5182 3.96981 9.05612 4.3105 8.71543C4.65119 8.37474 5.11327 8.18334 5.59508 8.18334C6.07689 8.18334 6.53897 8.37474 6.87966 8.71543C7.22035 9.05612 7.41175 9.5182 7.41175 10C7.41175 10.4818 7.22035 10.9439 6.87966 11.2846C6.53897 11.6253 6.07689 11.8167 5.59508 11.8167C5.11327 11.8167 4.65119 11.6253 4.3105 11.2846C3.96981 10.9439 3.77841 10.4818 3.77841 10ZM17.7084 15.5058H12.9184M12.9184 15.5058C12.9184 15.9878 12.7266 16.4504 12.3858 16.7911C12.045 17.1319 11.5828 17.3233 11.1009 17.3233C10.6191 17.3233 10.157 17.1311 9.81634 16.7904C9.47565 16.4497 9.28425 15.9876 9.28425 15.5058M12.9184 15.5058C12.9184 15.0239 12.7266 14.5622 12.3858 14.2214C12.045 13.8806 11.5828 13.6892 11.1009 13.6892C10.6191 13.6892 10.157 13.8806 9.81634 14.2213C9.47565 14.562 9.28425 15.024 9.28425 15.5058M9.28425 15.5058H2.29175M17.7084 4.49417H15.1209M11.4867 4.49417H2.29175M11.4867 4.49417C11.4867 4.01236 11.6781 3.55029 12.0188 3.20959C12.3595 2.8689 12.8216 2.67751 13.3034 2.67751C13.542 2.67751 13.7782 2.7245 13.9986 2.81579C14.219 2.90709 14.4193 3.0409 14.588 3.20959C14.7567 3.37829 14.8905 3.57856 14.9818 3.79896C15.0731 4.01937 15.1201 4.2556 15.1201 4.49417C15.1201 4.73274 15.0731 4.96897 14.9818 5.18938C14.8905 5.40979 14.7567 5.61006 14.588 5.77875C14.4193 5.94744 14.219 6.08126 13.9986 6.17255C13.7782 6.26385 13.542 6.31084 13.3034 6.31084C12.8216 6.31084 12.3595 6.11944 12.0188 5.77875C11.6781 5.43806 11.4867 4.97598 11.4867 4.49417Z'
                      stroke='white'
                      stroke-width='1.5'
                      stroke-miterlimit='10'
                      stroke-linecap='round'
                    />
                  </svg>
                  <span>Filter Data</span>
                </Button> */}
                {/* <AddIcon onClick={() => {
                        addStaffAccess();
                      }} /> */}

                <Tooltip title='Add New Staff Access' followCursor>
                          <IconButton
                            style={{
                              width:'50px',height:'50px',
                                    backgroundColor: '#FF5939',
                                    color: '#ffffff',                                    
                                  }                               
                            }
                            variant='contained'
                            aria-label='Add'
                            size='small'
                            onClick={() => {
                              setIsShowAddStaffAccess(true);
                            }}                            
                          >
                            <AddIcon />
                          </IconButton>
                        </Tooltip>
              </Stack>
            </Grid>
          </Grid>

          <div className='content-container'>
            {/* Students Tab Content */}

            <div className='tabs-content'>
              <StripedDataGrid
                rows={staffAccess}              
                columns={columns}
                pagination
                 getRowId={(row) => row.Id}
                //onRowClick={(row) => handleEmailDetail(row.id)}
                checkboxSelection={false}
                sortingOrder={['desc', 'asc', null]}
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                }
                rowHeight={80}               
                disableColumnMenu
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                disableExportButton
                disableSelectionOnClick
                rowsPerPageOptions={[10, 30, 50, 70, 100]}
              />              
            </div>
          </div>

          {!isMatAccount && isShowAddStaffAccess ? (
            <AddStaffAccess
              handleClose={handleClose}
              getStaff={getStaff}
              matSchools={null}
              isMat={false}
              schoolId={schoolId}
            />
          ) : (
            <div></div>
          )}

          {!isMatAccount && isShowEditStaffAccess ? (
            <EditStaffAccess
              handleClose={handleClose}
              staffId={staffId}
              matSchools={null}
              isMat={false}
              schoolId={schoolId}
              getStaff={getStaff}
            />
          ) : (
            <div></div>
          )}

          {isMatAccount && isShowAddStaffAccess ? (
            <AddStaffAccess
              handleClose={handleClose}
              matSchools={matSchools}
              getMatStaff={getMatStaff}
              isMat={true}
              schoolId={''}
            />
          ) : (
            <div></div>
          )}

          {isMatAccount && isShowEditStaffAccess ? (
            <EditStaffAccess
              handleClose={handleClose}
              staffId={staffId}
              matSchools={matSchools}
              getMatStaff={getMatStaff}
              isMat={true}
              schoolId={''}
            />
          ) : (
            <div></div>
          )}
        </PeopleManagement>
      </Container>
    </>
  );
};

export default AccessManagement;
