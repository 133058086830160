import React, { useState, useEffect } from 'react';
import { Grid, Button, Typography, Stack } from '@mui/material';
import { storageService } from '../../services';
import { PeopleMgtSwitch } from './PeopleMgtStyle';
import { useFetchStudentSettings } from '../../apis/SyncSettings/hooks/useFetchStudentSettings';
import { useSaveStudentSettings } from '../../apis/SyncSettings/hooks/useSaveStudentSettings';
import LoaderWrapper from '../../components/common/LoaderWrapper';
import { WebStorageNames } from '../../utils';
const validFields = [
  { field: 'admissionDate', label: 'Admission Date' },
  { field: 'ealStatus', label: 'EAL Status' },
  { field: 'giftedAndTalented', label: 'Gifted and Talented' },
  { field: 'house', label: 'House' },
  { field: 'sibblingAtSchool', label: 'Sibbling at School' },
  { field: 'achievementRecorded', label: 'Achievement Recorded' },
  { field: 'fsm', label: 'FSM' },
  { field: 'serviceChild', label: 'Service Child' },
  { field: 'senStatus', label: 'SEN Status' },
  { field: 'registration', label: 'Registration' },
  { field: 'boardingStatus', label: 'Boarding Status' },
  { field: 'everExcluded', label: 'Ever Excluded' },
  { field: 'pupilPremium', label: 'Pupil Premium' },
  { field: 'everInCare', label: 'Ever in Care' },
  { field: 'campus', label: 'Campus' },
  { field: 'behaviourRecorded', label: 'Behaviour Recorded' },
];

function StudentSyncToggleSetting() {
 const matGroupId = storageService.getItem(WebStorageNames.MatGroupId);
  const isMat = storageService.getItem(WebStorageNames.IsMat);
  const schoolId = storageService.getItem(WebStorageNames.SchoolId);

  const isGroupEnabled = isMat && !!matGroupId;
  const id = isGroupEnabled ? matGroupId : schoolId;

  const { data, isLoading } = useFetchStudentSettings(id, isGroupEnabled);
  const { mutate: saveSettings } = useSaveStudentSettings(isGroupEnabled);

  const [toggles, setToggles] = useState({});

  useEffect(() => {
    if (data && data.length > 0) {
      const firstRecord = data[0];
      const filteredData = validFields.reduce((acc, { field }) => {
        acc[field] = firstRecord[field] ?? false;
        return acc;
      }, {});
      setToggles(filteredData);
    }
  }, [data]);

  const handleToggle = (name) => {
    setToggles((prevState) => ({ ...prevState, [name]: !prevState[name] }));
  };

  const handleSave = () => {
    if (!id) return; 
    saveSettings({ ...toggles, GroupId: matGroupId, SchoolId: schoolId });
  };
 

  return (
    <LoaderWrapper isLoading={isLoading || saveSettings.isLoading}>
    <Grid container spacing={2.5} mt={2}>
       {validFields.map(({ field, label }) => (
          <Grid item xs={12} sm={4} md={3} key={field}>
            <Stack direction="row" spacing={1} alignItems="center">
              <PeopleMgtSwitch
                checked={toggles[field]}
                onChange={() => handleToggle(field)}
              />
              <Typography variant="body2">{label}</Typography>
            </Stack>
          </Grid>
        ))}
      <Grid item xs={12}>
        <Stack direction="row" justifyContent="flex-end" spacing={1}>
          <Button
                     variant='outlined'
                     className='save-btn'
                     disableRipple
                     onClick={handleSave}
                   >Save</Button>
        </Stack>
      </Grid>
    </Grid>
    </LoaderWrapper>
  );
}

export default StudentSyncToggleSetting;
