

import { HandleApiToast } from '../../../components/common/HandleApiToast';
import { useQuery } from '@tanstack/react-query';
import { fetchStudentSettings } from '../SyncSettingsService';

export const useFetchStudentSettings = (id, isGroup) => {
  return useQuery(
    ['studentSettings', id, isGroup],
    () => fetchStudentSettings(id, isGroup),
    {
      enabled: !!id,
      onError: (error) => HandleApiToast('error', error, 'Failed to fetch student settings.'),
    }
  );
};
