import React, { useState, useEffect } from 'react';
import { Grid, Button, Typography, Stack } from '@mui/material';
import { storageService } from '../../services';
import { PeopleMgtSwitch } from './PeopleMgtStyle';
import { useFetchParentSettings } from '../../apis/SyncSettings/hooks/useFetchParentSettings';
import { useSaveParentSettings } from '../../apis/SyncSettings/hooks/useSaveParentSettings';
import LoaderWrapper from '../../components/common/LoaderWrapper';
import { WebStorageNames } from '../../utils';

const validFields = [
  { field: 'dateOfBirth', label: 'Date of Birth' },
  { field: 'parentResponsibility', label: 'Parent Responsibility' },
];

function ParentSyncSetting() {
  const matGroupId = storageService.getItem(WebStorageNames.MatGroupId);
  const isMat = storageService.getItem(WebStorageNames.IsMat);
  const schoolId = storageService.getItem(WebStorageNames.SchoolId);

  const isGroupEnabled = isMat && !!matGroupId;
  const id = isGroupEnabled ? matGroupId : schoolId;

  const { data, isLoading } = useFetchParentSettings(id, isGroupEnabled);
  const { mutate: saveSettings } = useSaveParentSettings(isGroupEnabled);

  const [toggles, setToggles] = useState({});

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      const firstRecord = data[0];
      const filteredData = validFields.reduce((acc, { field }) => {
        acc[field] = firstRecord[field] ?? false;
        return acc;
      }, {});
      setToggles(filteredData);
    }
  }, [data]);

  const handleToggle = (field) => {
    setToggles((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const handleSave = () => {
    if (!id) return;

    const dataToSave = { ...toggles, GroupId: matGroupId, SchoolId: schoolId };
    saveSettings(dataToSave);
  };

  return (
    <LoaderWrapper isLoading={isLoading || saveSettings.isLoading}>
      <Grid container spacing={2.5} mt={2}>
        {validFields.map(({ field, label }) => (
          <Grid item xs={12} sm={4} md={3} key={field}>
            <Stack direction="row" spacing={1} alignItems="center">
              <PeopleMgtSwitch
                checked={toggles[field]}
                onChange={() => handleToggle(field)}
              />
              <Typography variant="body2">{label}</Typography>
            </Stack>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            <Button
              variant="outlined"
              className="save-btn"
              disableRipple
              onClick={handleSave}
            >
              Save
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </LoaderWrapper>
  );
}

export default ParentSyncSetting;
