import { useQuery } from '@tanstack/react-query';

import { HandleApiToast } from '../../../components/common/HandleApiToast';
import { fetchSubjectSettings } from '../SyncSettingsService';


export const useFetchSubjectSettings = (id, isGroup) => {
  return useQuery(
    ['subjectSettings', id, isGroup],
    () => fetchSubjectSettings(id, isGroup),
    {
      enabled: !!id,
      onError: (error) => HandleApiToast('error', error, 'Failed to fetch subject settings.'),
    }
  );
};


