import React, { useState } from 'react';
import { LeftMenu, MainHeader } from '../../components';
import { Tab, Tabs, Container } from '@mui/material';
import ToggleSwitch from './ToggleSwitch';
import StudentSyncToggleSetting from './StudentSyncToggleSetting';
import ParentSyncSetting from './ParentSyncSetting';
import ClassesSyncSetting from './ClassesSyncSetting';
import AttendanceSyncSetting from './AttendanceSyncSetting';
import AchievementSyncSetting from './AchievementSyncSetting';
import BehaviourSyncSetting from './BehaviourSyncSetting';
import SubjectSyncSetting from './SubjectSyncSetting';
import { SyncSettings } from './PeopleMgtStyle';
import ResponsiveAppBar from '../../components/common/ResponsiveAppBar';
import { SyncTabEnum } from '../../utils/Constants';

const SyncSetting = () => {
  const [tabValue, setTabValue] = useState(SyncTabEnum.STAFF);

  // Function to handle tab change
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <>
  
    
      <Container maxWidth="lg">
    
        <h3>Sync Setting</h3>
        <SyncSettings>
          <div className='sync-tabs'>
            <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab label='STAFF' />
              <Tab label='STUDENT' />
              <Tab label='PARENTS' />
              <Tab label='CLASSES' />
              <Tab label='ATTENDANCE' />
              <Tab label='BEHAVIOUR' />
              <Tab label='ACHIEVEMENT' />
              <Tab label='SUBJECTS' />
            </Tabs>
          </div>

          <div className='content-container'>
            {tabValue === SyncTabEnum.STAFF && (
              <div className='tabs-content'>
                <p>Choose which data to be Sync from your MIS below:</p>
                <div>
                  <ToggleSwitch />
                </div>
              </div>
            )}

            {tabValue === SyncTabEnum.STUDENT && (
              <div className='tabs-content'>
                <p>
                  As Minimum Name, Date of Birth, Email, MIS Id, NC Year Group
                  and form will be Synced. You can choose the addition data to
                  sync below:
                </p>
                <div>
                  <StudentSyncToggleSetting />
                </div>
              </div>
            )}

            {tabValue === SyncTabEnum.PARENTS && (
              <div className='tabs-content'>
                <p>
                  As Minimum Name, Date of Birth, Email, MIS Id will be Synced.
                  You can choose the addition data to sync below:
                </p>
                <div>
                  <ParentSyncSetting />
                </div>
              </div>
            )}

            {tabValue === SyncTabEnum.CLASSES && (
              <div className='tabs-content'>
                <p>
                  As Minimum Expect below all field will be Synced. You can
                  choose the addition data to sync below:
                </p>
                <div>
                  <ClassesSyncSetting />
                </div>
              </div>
            )}

            {tabValue === SyncTabEnum.ATTENDANCE && (
              <div className='tabs-content'>
                <p>
                  As Minimum Expect below all field will be Synced. You can
                  choose the addition data to sync below:
                </p>
                <div>
                  <AttendanceSyncSetting />
                </div>
              </div>
            )}

            {tabValue === SyncTabEnum.BEHAVIOUR && (
              <div className='tabs-content'>
                <p>
                  As Minimum Expect below all field will be Synced. You can
                  choose the addition data to sync below:
                </p>
                <div>
                  <BehaviourSyncSetting />
                </div>
              </div>
            )}

            {tabValue === SyncTabEnum.ACHIEVEMENT && (
              <div className='tabs-content'>
                <p>
                  As Minimum Expect below all field will be Synced. You can
                  choose the addition data to sync below:
                </p>
                <div>
                  <AchievementSyncSetting />
                </div>
              </div>
            )}

            {tabValue === SyncTabEnum.SUBJECTS && (
              <div className='tabs-content'>
                <p>
                  As Minimum Expect below all field will be Synced. You can
                  choose the addition data to sync below:
                </p>
                <div>
                  <SubjectSyncSetting />
                </div>
              </div>
            )}
          </div>
        </SyncSettings>
      </Container>
    </>
  );
};

export default SyncSetting;
