import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import OutlinedInput from "@mui/material/OutlinedInput";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import * as Yup from 'yup';
import { AppModel } from '../../components';
import {
  AppButton,
  customStyles,
  ErrorWrapper,
  StaffWrapper,
} from '../../resources/styling/appStyle';
import { colors } from '../../resources/theme';
import { schoolStaffService,matStaffService } from '../../services';
import { loaderAction } from '../../store/loaderStore';
import { DateFormats } from '../../utils/Constants';
import { RoleSuggestion } from '../otherSuggestion';
const { purple } = colors;



const AddStaffLevelAccess = (props) => {
  const [staff, setStaff] = useState('');
  const [matSchool, setMatSchool] = useState('');
  const [jobRole] = useState('');
  const [type, setType] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [filterValues, setFilterValues] = useState([]);
  const [schoolFilters, setSchoolFilters] = useState([]);
  const [filterLibrary, setFilterLibrary] = useState([]);
  const { loaderActions } = props;
  const [jobTitles, setJobTitles] = useState([]);
  const [jobTitleInputValue, setJobTitleInputValue] = useState('');
  const [staffValues, setStaffValues] = useState('');  
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [queryfilters, setQueryFilter] = useState([]);
  const [filterDropDownMaxSize] = useState(500);
  const [submitedAddFilters, setSubmitedAddFilters] = useState([]);
  const [roleName, setRoleName] = useState([]);

  const roles = [
    {id:0, name: "Analyse"},
    {id:1, name: "Respond"},
    {id:2, name: "Survey Administration"},
    {id:3, name: "Data Administration"},
    {id:4, name: "Users Administration"},
    {id:5, name: "Owner"} 
 ]

  const [role, setRole] = useState([]);

  const [school, setSchool] = useState([]);

  useEffect(() => {
    if (!props.isMat) {
      getSchoolFilters(props.schoolId);
      getFilterValues(props.schoolId);
      getFilterLibrary();
      getstaffList();
    }
  }, [props.isMat]);

  useEffect(() => {
    if (props.isMat) {
      getFilterLibrary();
      getstaffList();      
    }
  }, [props.isMat]);

  useEffect(() => {
    if (!props.isMat) {
      initialValues(props.schoolId);
    }
  }, []);

  useEffect(() => {
    let filters =
      [...filterLibrary] !== null
        ? [...filterLibrary]
          .filter((x) => x.KeyWord !== null && x.KeyWord !== '')
          .filter((x) =>
            x.KeyWord.toLowerCase().includes(jobTitleInputValue.toLowerCase())
          )
        : null;
    setQueryFilter([...filters].slice(0, filterDropDownMaxSize));
  }, [jobTitleInputValue]);

  const initialValues = (schoolId) => {
    setMatSchool(schoolId);
    //alert(schoolId);
    console.log(matSchool);
  };

  async function getstaffList(){
    var isMat = localStorage.getItem('IsMat');    
    var matId = localStorage.getItem('MatGroupId');
    var schoolId = localStorage.getItem('schoolId');
    if (!isMat) {         
            schoolStaffService
            .getBySchoolId(schoolId)
            .then((response) => {              
                const { data, success } = response;
                if (success) {
                    setStaffValues(data.Data);
                    // const dataRow = [...data.Data].map((item, index) => {                    
                    // return {
                    //     id: item.Id,
                    //     firstName: item.FirstName,
                    //     lastName: item.LastName,
                    //     email: item.Email,
                    //     accessType: item.AccessType,
                    //     jobRole: item.JobRole,
                    //     accessLevel: item.IsGranted ? item.AccessLevel : null,
                    //     coreFilters: item.CoreFilters,
                    //     filters: item.FilterValues,
                    // };
                    // });
                    //setStaffValues(dataRow);
                    //console.log('School Staff Data: '+dataRow[0].email);
                }

                
            })
            .catch((error) => {
                loaderActions.dataLoading(false);
            });
        }
        else{       
            const model = {
                Start: 0,
                Length: 2000,
                Order: null,
                Filter: null,
                Search: {
                  MatGroupId: matId,
                  Value: null,
                },
                Columns: [],
              };
            matStaffService
            .getStaff(model)
            .then((response) => {                
                const { data, success } = response;
                if (success) {  
                    setStaffValues(data.Data);                                    
                    // const dataRow = [...data.Data].map((item, index) => {
                    //     return {
                    //       id: item.Id,
                    //       firstName: item.FirstName,
                    //       lastName: item.LastName,
                    //       school: item.School.Name,
                    //       email: item.Email,
                    //       accessType: item.AccessType,
                    //       jobRole: item.JobRole,
                    //       accessLevel: item.IsGranted ? item.AccessLevel : null,
                    //       coreFilters: item.CoreFilters,
                    //       filters: item.FilterValues,
                    //     };
                    //   });
                      //setStaffValues(dataRow);
                      //console.log('Mat Staff Data: '+dataRow[0].email);
                }
            })
            .catch((error) => {
                loaderActions.dataLoading(false);
            });
        }
    }

  const getFilterValues = (schoolId) => {
    loaderActions.dataLoading(true);
    schoolStaffService
      .getFilterValuesBySchoolId(schoolId)
      .then((response) => {
        loaderActions.dataLoading(false);
        const { data, success } = response;
        if (success) {
          setFilterValues(data);
        }
      })
      .catch((error) => {
        loaderActions.dataLoading(false);
      });
  };

  const getFilterLibrary = () => {
    loaderActions.dataLoading(true);
    schoolStaffService
      .getFilterLibrary()
      .then((response) => {
        loaderActions.dataLoading(false);
        const { data, success } = response;
        if (success) {
          setFilterLibrary(data);
          setQueryFilter([...data].slice(0, filterDropDownMaxSize));
        }
      })
      .catch((error) => {
        loaderActions.dataLoading(false);
      });
  };

  const getSchoolFilters = (schoolId) => {
    loaderActions.dataLoading(true);
    schoolStaffService
      .getFiltersBySchoolId(schoolId)
      .then((response) => {
        loaderActions.dataLoading(false);
        const { data, success } = response;
        if (success) {           
          setSchoolFilters(data);
        }
      })
      .catch((error) => {
        loaderActions.dataLoading(false);
      });
  };

  const handleSchoolChange = (event) => {
    setMatSchool(event.target.value);
    let schoolId = event.target.value;
    getSchoolFilters(schoolId);
    getFilterValues(schoolId);
  };  

  const handleStaffChange = (event) => {        
    setStaff(event.target.value);
    let staffId = event.target.value;   
    //alert(staffId)
       
    const filteredUsers = [...staffValues].find(obj => {
      return obj.Id === staffId;
    });
     
    // console.log(filteredUsers);  
    // console.log(filteredUsers.FirstName);  
    setFirstName(filteredUsers.FirstName);
     setLastName(filteredUsers.LastName);
     setEmail(filteredUsers.Email);
    // getFilterValues(schoolId);
  };
  

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const handleDateChange = (date) => {
    setStartDate(date);
  };

  const handleJobTitleChange = (event, values) => {
    setJobTitles(values);
  };

  const handleAddFilterChange = (e, v, obj) => {
    let array = [...submitedAddFilters]
    var filtered = [...array].filter(function (el) { return el.key != obj.Id; });

    if (v != null && v !== '' && v !== "" && v !== undefined) {
      filtered.push({
        filter: obj.Attribute,
        value: v,
        key: obj.Id,
      })
    }
    setSubmitedAddFilters([...filtered])
  }

  function getFiltersOnSubmit(values) {
    let filterValues = [];
    schoolFilters.map((item) => {
      const filterValue = submitedAddFilters.find(obj => {
        return obj.key === item.Id;
      });
      if (
        filterValue !== undefined &&
        (filterValue !== null || filterValue !== '')
      ) {
        let filterValueModel = {
          FilterId: filterValue.key,
          Value: filterValue.value,
          SchoolId: values.matSchool,
        };
        filterValues.push(filterValueModel);
      }
    });
    return filterValues;
  }


  // const handleRoleChange = (event: SelectChangeEvent<typeof roleName>) => {
  //   const {
  //     target: { value }
  //   } = event;
  //   setRoleName(
  //     // On autofill we get a stringified value.
  //     typeof value === "string" ? value.split(",") : value
  //   );
  // };

  function getCoreFiltersOnSubmit() {
    let coreFilters = [];
    for (let i = 0; i < jobTitles.length; i++) {
      let coreFilter = {
        JobTitle: jobTitles[i].title,
      };
      coreFilters.push(coreFilter);
    }
    return coreFilters;
  }

  const filterValueSuggestions =
    filterValues !== null
      ? filterValues
        .sort((a, b) => (a.Value > b.Value ? 1 : -1))
        .map((item, i) => {
          return {
            title: item.Value,
            key: item.FilterId,
          };
        })
      : null;

  const jobTitleSuggestions =
    [...queryfilters] !== null
      ? [...queryfilters]
        .filter((x) => x.KeyWord !== null && x.KeyWord !== '')
        .sort((a, b) => (a.KeyWord > b.KeyWord ? 1 : -1))
        .map((item, i) => {
          return {
            title: item.KeyWord,
            key: item.Id,
          };
        })
      : null;

  const schoolItems = 
    props.matSchools !== null
      ? props.matSchools.map((item) => (
        <MenuItem key={item.Id} value={item.Id}>
          {item.Name}
        </MenuItem>
      ))
      : null;


 const staffItems = 
    staffValues !== null
        ? [...staffValues].map((item) => (
          <MenuItem key={item.Id} value={item.Id}>
            {item.FirstName +' '+item.LastName }
          </MenuItem>
        ))
        : null;       
      

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        firstName: firstName !== null ? firstName : '',
        lastName: lastName !== null ? lastName : '',
        email: email !== null ? email : '',
        matSchool: matSchool !== null ? matSchool : '',
        staff: staff !== null ? staff : '',
        jobRole: jobRole === null || jobRole == '' ? 0 : jobRole,
        type: type === null || type == '' ? 0 : type,
        startDate: startDate !== null ? startDate : new Date(),
        jobTitles: jobTitles !== null ? jobTitles : [],
      }}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().trim().required('First name is required.'),
        lastName: Yup.string().trim().required('Last name is required.'),
        email: Yup.string()
          .trim()
          .email('Provide email address like username@gmail.com')
          .matches(
            /^[\w%\+\-]+(\.[\w%\+\-]+)*@[\w%\+\-]+(\.[\w%\+\-]+)+$/,
            "Provide email address like username@gmail.com"
          )
          .required('Email is required.'),
        matSchool: Yup.string().trim().required('School is required.'),
      })}

      onSubmit={(values, formProps) => {
        setBtnDisabled(true);
        var isMat = localStorage.getItem('IsMat');    
        var matId = localStorage.getItem('MatGroupId');
        var schoolId = localStorage.getItem('schoolId');           
       
        let model = {
          FirstName: values.firstName,
          LastName: values.lastName,
          Email: values.email,
          SchoolId: values.matSchool,
          // StartDate: values.startDate,
          // EmploymentType: values.type,
          // CoreFilters: coreFilters,
          // FilterValues: filterValues
        };
        debugger;
        if(!isMat)
          {
            fetch(             
              `https://api.admin.welbee.co.uk/api/UserAccess/AddUserAccess?access=${roleName}&department=&sites=${values.matSchool}&firstName=${values.firstName}&lastName=${values.lastName}&email=${values.email}&staffId=${staff}&schoolId=${schoolId}&matId=0&type=2`
              ,{
                method: 'POST',mode: 'no-cors','Access-Control-Allow-Origin': 'https://api.admin.welbee.co.uk'
              }            
            )
            .then((response) => {    
              toast.success('Record added successfully.');                
              props.handleClose();
              return response.json();
            })
            .then((data) => {              
              console.log(data)
              return data.data;
            });
          }
          else{
            fetch(
              `https://api.admin.welbee.co.uk/api/UserAccess/AddUserAccess?access=${roleName}&department=&sites=${values.matSchool}&firstName=${values.firstName}&lastName=${values.lastName}&email=${values.email}&staffId=${staff}&schoolId=${schoolId}&matId=${matId}&type=1`      
              ,{
                method: 'POST',mode: 'no-cors','Access-Control-Allow-Origin': 'https://api.admin.welbee.co.uk'
              }    
            )
            // .then((response) => {
            //   setBtnDisabled(false);
            //   if (response.success) {
            //     toast.success('Record added successfully.');                
            //     props.handleClose();
            //   } else if (
            //     response.message === 'email already exist' ||
            //     response.message === 'email should be example@domain.com'
            //   ) {
            //     formProps.setErrors({ email: response.message });
            //   } else {
            //     toast.error('Record not added. Please try again later.');
            //   }
            // })
            .then((response) => {    
              toast.success('Record added successfully.');                
              props.handleClose();
              return response.json();
            })
            .then((data) => {          
              console.log(data)
              return data.data;
            });
          }

        // schoolStaffService
        //   .saveSchoolStaff(model)
        //   .then((response) => {
        //     setBtnDisabled(false);
        //     if (response.success) {
        //       toast.success('Record added successfully.');
        //       if (!props.isMat) props.getStaff();
        //       else props.getMatStaff();
        //       props.handleClose();
        //     } else if (
        //       response.message === 'email already exist' ||
        //       response.message === 'email should be example@domain.com'
        //     ) {
        //       formProps.setErrors({ email: response.message });
        //     } else {
        //       toast.error('Record not added. Please try again later.');
        //     }
        //   })
        //   .catch((error) => {
        //     setBtnDisabled(false);
        //   });
      }}
      render={({ values, touched, errors, handleSubmit, setFieldValue }) => {
        return (
          <Grid item xs={12}>
            <AppModel
              onClose={props.handleClose}
              styles={customStyles}
              open={true}
              titleText={'Add Staff Access'}
              bodyText={
                <div>
                  <p style={{ marginTop: '0px', marginBottom: '25px' }}>
                    Add your new staff member's Access.
                  </p>
                  <Box
                    component='form'
                    sx={{
                      '& .MuiTextField-root': { width: '100%' },
                    }}
                    autoComplete='off'
                  >
                    <StaffWrapper>
                      <Grid container spacing={2}>
                        <Grid item md={12} sm={12} xs={12}>
                            <FormControl fullWidth>
                              <InputLabel id='staff'>Staff</InputLabel>
                              <Select                              
                                name='staff'
                                labelId='school-staff'                        
                                id='staff'
                                value={staff}
                                label='Staff'
                                onChange={(e) => {
                                    setFieldValue('staff', e.target.value);
                                    handleStaffChange(e);
                                }}
                              >
                                {staffItems}
                              </Select> 

                              {/* <Select
                                multiple
                                value={staffValues}
                                //onChange={handleChangeUserContributores}
                                className="multi-input-padding"
                                validators={["required"]}
                                errorMessages={["Select Application Admin"]}
                                // input={
                                //     <OutlinedInput
                                //     labelWidth={labelWidth}
                                //     id="select-multiple-checkbox"
                                //     />
                                // }
                                MenuProps={staffValues}
                                >
                                {staffValues.map((item, index) => (
                                    <MenuItem key={item.id} value={item.id}>
                                    {item.FirstName}
                                    </MenuItem>
                                ))}
                                </Select>                                                 */}
                            </FormControl>

                            {/* <ErrorWrapper>
                              {touched.matSchool && errors.matSchool}
                            </ErrorWrapper> */}
                          </Grid>

                        <Grid item md={6} sm={6} xs={12}>
                          <TextField
                            fullWidth
                            name='firstName'
                            value={firstName==''?'':firstName}
                            id='first-name'
                            label='First Name'
                            onChange={(e) => {
                              setFieldValue('firstName', e.target.value);
                              setFirstName(e.target.value);
                            }}
                          />

                          <ErrorWrapper>
                            {touched.firstName && errors.firstName}
                          </ErrorWrapper>
                        </Grid>

                        <Grid item md={6} sm={6} xs={12}>
                          <TextField
                            fullWidth
                            name='lastName'
                            value={lastName==''?'':lastName}
                            id='last-name'
                            label='Last Name'
                            onChange={(e) => {
                              setFieldValue('lastName', e.target.value);
                              setLastName(e.target.value);
                            }}
                          />

                          <ErrorWrapper>
                            {touched.lastName && errors.lastName}
                          </ErrorWrapper>
                        </Grid>

                        <Grid item md={6} sm={6} xs={12}>
                          <TextField
                            fullWidth
                            name='email'
                            value={email==''?'':email}
                            id='staff-email'
                            label='Email'
                            type='email'
                            onChange={(e) => {
                              setFieldValue('email', e.target.value);
                              setEmail(e.target.value);
                            }}
                          />

                          <ErrorWrapper>
                            {touched.email && errors.email}
                          </ErrorWrapper>
                        </Grid>

                        <Grid item md={6} sm={6} xs={12}>
                          <FormControl fullWidth>
                            {/* <InputLabel id='staff-role'>Role</InputLabel>                                                       */}
                           <Autocomplete
                                multiple
                                limitTags={1}
                                labelId='staff-role'
                                label='Role'
                                value={role}
                                onChange={(event, newValue) => {
                                  // alert(newValue[0].name);                                
                                var concanatedRoleVal= '';                                  
                                  Object.keys(newValue).forEach(function(key) {
                                    concanatedRoleVal += newValue[key].id+',';
                                  });
                                  concanatedRoleVal.trimEnd(',');
                                  // alert(concanatedRoleVal);
                                  setRoleName(concanatedRoleVal);                                
                                  setRole(newValue);                                                                  
                                }}
                                id="Role-filter"
                                options={roles}
                                sx={{'& ::placeholder':{color: 'rgba(0, 0, 0.2, 1)',
                                  'font-family': 'Avenir LT Pro',
                                  'font-weight': '400',
                                  'font-size': '1rem',
                                  'line-height': '1.4375em',
                                 }}}
                                getOptionLabel={(option) => option.name}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                renderInput={(params) => (
                                    <TextField {...params} placeholder='Role'/>
                                )}
                                />
                               
                        </FormControl>
                        </Grid>                       

                        {props.isMat ? (
                          <Grid item md={12} sm={12} xs={12}>
                            <FormControl fullWidth>
                              <InputLabel id='mat-schools'>School</InputLabel>
                              <Select                              
                                name='matSchool'                               
                                labelId='mat-schools'
                                id='schools'
                                value={matSchool}
                                label='School'
                                onChange={(e) => {
                                  setFieldValue('matSchool', e.target.value);                                 
                                  handleSchoolChange(e);
                                }}
                              >
                                {schoolItems}
                              </Select>
                              
                              {/* <Autocomplete
                                multiple
                                name='matSchool'
                                limitTags={1}
                                value={props.matSchools}
                                onChange={(event, newValue) => {
                                    setRole(newValue);
                                }}
                                id="schools"
                                options={props.matSchools}
                                getOptionLabel={(option) => option.Name}                            
                                // isOptionEqualToValue={(option, value) => option.Id === value.Id}
                                renderInput={(params) => (
                                    <TextField {...params} />
                                )}
                                /> */}
                            </FormControl>

                            {/* <ErrorWrapper>
                              {touched.matSchool && errors.matSchool}
                            </ErrorWrapper> */}
                          </Grid>
                        ) : null}

                        

                        {/* Staff Core Filters */}
                        <Grid item md={6} sm={6} xs={12} sx={{paddingTop: '0 !important',display:'none'}}>
                          <FormControl fullWidth >
                            <Autocomplete                    
                              multiple
                              freeSolo={false}
                              name='jobTitles'                                                      
                              inputValue={jobTitleInputValue}
                              options={[
                                ...new Set(
                                  jobTitleSuggestions.map((opt) => opt)
                                ),
                              ]}
                              onChange={handleJobTitleChange}
                              onInputChange={(e, value) => {
                                setJobTitleInputValue(value);
                              }}
                              getOptionLabel={(option) => option.title}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={'Job Titles'}
                                  margin='normal'                                  
                                />
                              )}
                              renderOption={(props, option, { inputValue }) => {
                                const matches = match(option.title, inputValue);
                                const parts = parse(option.title, matches);

                                return (
                                  <li {...props}>
                                    <div>
                                      {parts.map((part, index) => (
                                        <span
                                          key={index}
                                          style={{
                                            fontWeight: part.highlight
                                              ? 700
                                              : 400,
                                          }}
                                        >
                                          {part.text}
                                        </span>
                                      ))}
                                    </div>
                                  </li>
                                );
                              }}                              
                            />
                          </FormControl>
                        </Grid>

                        {/* Staff Core Filter End */}

                        {/* Staff Additional Filters Start */}

                        {schoolFilters.map((obj, index) => (
                          <Grid item md={6} sm={6} xs={12}>
                            <FormControl fullWidth >
                              <Autocomplete
                                freeSolo={true}
                                name={obj.Attribute}
                                options={[
                                  ...new Set(
                                    filterValueSuggestions.filter(
                                      (x) => x.key == obj.Id
                                    )
                                  ),
                                ]}
                                onInputChange={(e, v) => {
                                  // setFieldValue(obj.Attribute, {
                                  //   value: v,
                                  //   key: obj.Id,
                                  // });
                                  handleAddFilterChange(e, v, obj);
                                }}
                                getOptionLabel={(option) => option.title}
                                renderInput={(params) => (
                                  <TextField {...params} label={obj.Attribute} />
                                )}
                                renderOption={(props, option, { inputValue }) => {
                                  const matches = match(option.title, inputValue);
                                  const parts = parse(option.title, matches);

                                  return (
                                    <li {...props}>
                                      <div>
                                        {parts.map((part, index) => (
                                          <span
                                            key={index}
                                            style={{
                                              fontWeight: part.highlight
                                                ? 700
                                                : 400,
                                            }}
                                          >
                                            {part.text}
                                          </span>
                                        ))}
                                      </div>
                                    </li>
                                  );
                                }}
                              />
                            </FormControl>
                          </Grid>
                        ))}
                      </Grid>

                      <AppButton
                        color={purple}
                        type='button'
                        onClick={handleSubmit}
                        style={{ marginTop: '20px' }}
                      >
                        {btnDisabled ? 'Saving...' : 'Save'}
                      </AppButton>
                    </StaffWrapper>
                    {/* School Filters End */}
                  </Box>
                </div>
              }
            />
          </Grid>
        );
      }}
    />
  );
};

const mapDispatchToProps = (dispatch) => ({
  loaderActions: bindActionCreators(loaderAction, dispatch),
});
export default connect(null, mapDispatchToProps)(AddStaffLevelAccess);
