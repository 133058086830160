import { useQuery } from '@tanstack/react-query';

import { HandleApiToast } from '../../../components/common/HandleApiToast';
import { fetchBehaviourSettings } from '../SyncSettingsService';

export const useFetchBehaviourSettings = (id, isGroup) => {
  return useQuery(
    ['behaviourSettings', id, isGroup],
    () => fetchBehaviourSettings(id, isGroup),
    {
      enabled: !!id,
      onError: (error) => HandleApiToast('error', error, 'Failed to fetch behaviour settings.'),
    }
  );
};

