import { useQuery } from '@tanstack/react-query';

import { HandleApiToast } from '../../../components/common/HandleApiToast';
import { fetchParentSettings } from '../SyncSettingsService';


export const useFetchParentSettings = (id, isGroup) => {
  return useQuery(
    ['parentSettings', id, isGroup],
    () => fetchParentSettings(id, isGroup),
    {
      enabled: !!id,
      onError: (error) => HandleApiToast('error', error, 'Failed to fetch parent settings.'),
    }
  );
};

