import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import KeyIcon from '@mui/icons-material/Key';
import LockIcon from '@mui/icons-material/Lock';
import UploadIcon from '@mui/icons-material/Upload';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import { GridActionsCellItem } from '@mui/x-data-grid';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { DirectorLeftMenu, MainMATHeader } from '../../../components';
import { ActionPlanMainWrapper } from '../../../components/common/ActionPlanStyle';
import ConfirmPopupForm from '../../../components/common/ConfirmPopupForm';
import { HeaderDataGrid } from '../../../resources/styling/appStyle';
import {
  matStaffService,
  schoolService,
  storageService,
} from '../../../services';
import { navigationActions } from '../../../store/headerStore';
import { loaderAction } from '../../../store/loaderStore';
import { WebStorageNames } from '../../../utils';
import {
  getSelectedRow,
  getStaffJobRole,
  getStaffJobTitle,
} from '../../../utils/CommonFunctions';
import AddStaffMember from '../../staff/AddStaffMember';
import EditStaffMember from '../../staff/EditStaffMember';
import GrantAccess from '../../staff/GrantAccess';
import RevokeAccess from '../../staff/RevokeAccess';
import MatStaffExcel from './MatStaffExcel';
import Alert from '@mui/material/Alert';
import VpnKeyOffIcon from '@mui/icons-material/VpnKeyOff';
import ResponsiveAppBar from '../../../components/common/ResponsiveAppBar';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
}));

const MatStaff = (props) => {
  const { loaderActions } = props;
  const [isShowExcelUpload, setIsShowExcelUpload] = useState(false);
  const [matGroupId, setMatGroupId] = useState('');
  const [matSchools, setMatSchools] = useState('');
  const [staffId, setStaffId] = useState(false);
  const [jobRole, setJobRole] = useState('');
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    id: false,
    accessLevel: false,
  });

  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
    queryOptions: { field: '', sort: '' },
    value: null,
    filterOptions: { field: '', value: 0 },
  });

  const [isShowAddStaff, setIsShowAddStaff] = useState(false);
  const [isShowEditStaff, setIsShowEditStaff] = useState(false);
  const [isShowGrantAccess, setIsShowGrantAccess] = useState(false);
  const [isShowRevokeAccess, setIsShowRevokeAccess] = useState(false);
  const [isRemovePopupOpen, setIsRemovePopupOpen] = useState(false);
  const [isShowInfoMsg, setIsShowInfoMsg] = useState(false);
  const [isUnVerifiedJobTitles, setIsUnVerifiedJobTitles] = useState(false);

  useEffect(() => {
    const matId = storageService.getItem(WebStorageNames.MatGroupId);
    setMatGroupId(matId);
    getMatSchools();
    scrollToTop();
  }, []);

  useEffect(() => {
    getMatStaff();
  }, [
    pageState.page,
    pageState.pageSize,
    pageState.queryOptions,
    pageState.value,
    pageState.filterOptions,
  ]);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleJobRoleChange = (e) => {
    setJobRole(e.target.value);
    setPageState((old) => ({
      ...old,
      isLoading: true,
      total: 0,
      page: 1,
      pageSize: old.pageSize,
      filterOptions: {
        field: 'Job Role',
        value: e.target.value,
      },
    }));
  };
  const handleSortModelChange = React.useCallback((sortModel) => {
    if (sortModel?.length > 0) {
      setPageState((old) => ({
        ...old,
        isLoading: true,
        total: 0,
        page: 1,
        pageSize: old.pageSize,
        queryOptions: {
          field: sortModel[0].field,
          sort: sortModel[0].sort,
        },
      }));
    }
  }, []);

  const handleSearch = (value) => {
    if (value?.length > 2 || value === '') {
      setPageState((old) => ({
        ...old,
        isLoading: true,
        total: 0,
        page: 1,
        pageSize: old.pageSize,
        value: value,
      }));
    }
  };
  const handleDelete = (id) => {
    schoolService
      .deleteSchoolStaff(id)
      .then((response) => {
        const { success } = response;
        if (success) {
          toast.success('Record deleted successfully.');
          setIsRemovePopupOpen(false);
          getMatStaff();
        }
      })
      .catch((error) => {});
  };
  const handleInfoMsgClose = () => {
    setIsShowInfoMsg(false);
  };
  const handleExcelUpload = () => {
    setIsShowInfoMsg(true);
  };
  const getMatSchools = () => {
    const matId = storageService.getItem(WebStorageNames.MatGroupId);
    matStaffService
      .matSchools(matId)
      .then((response) => {
        const { data, success } = response;
        if (success) {
          setMatSchools(data);
        } else {
        }
      })
      .catch((error) => {});
  };

  const getMatStaff = () => {
    const matId = storageService.getItem(WebStorageNames.MatGroupId);

    //Set PageState
    if (pageState !== undefined) {
      setPageState((old) => ({
        ...old,
        isLoading: true,
        data: old.data,
        total: old.total,
        queryOptions: old.queryOptions,
        filterOptions: old.filterOptions,
        value: old.value,
      }));
    }

    let filter = null;
    if (pageState?.filterOptions?.field !== '') {
      filter = [
        {
          Field: pageState.filterOptions.field,
          Value: pageState.filterOptions.value,
        },
      ];
    }

    let order = null;
    if (pageState?.queryOptions?.field !== '') {
      order = [
        {
          Field: pageState.queryOptions.field,
          Sort: pageState.queryOptions.sort,
        },
      ];
    }

    //Get Groups Of School
    const model = {
      Start: (pageState.page - 1) * pageState.pageSize,
      Length: pageState.pageSize,
      Order: order,
      Filter: filter,
      Search: {
        MatGroupId: matId,
        Value: pageState.value,
      },
      Columns: [],
    };

    matStaffService
      .getStaff(model)
      .then((response) => {
        const { data, success } = response;
        if (success) {
          const { IsJobRoleVerified } = data;
          if (!IsJobRoleVerified) setIsUnVerifiedJobTitles(true);
          const dataRow = [...data.Data].map((item, index) => {
            return {
              id: item.Id,
              firstName: item.FirstName,
              lastName: item.LastName,
              school: item.School.Name,
              email: item.Email,
              accessType: item.AccessType,
              jobRole: item.JobRole,
              accessLevel: item.IsGranted ? item.AccessLevel : null,
              coreFilters: item.CoreFilters,
              filters: item.FilterValues,
            };
          });
          setPageState((old) => ({
            ...old,
            isLoading: false,
            data: dataRow,
            total: data.RecordsFiltered,
          }));
        } else {
          setPageState((old) => ({
            ...old,
            isLoading: false,
          }));
        }
      })
      .catch((error) => {
        setPageState((old) => ({
          ...old,
          isLoading: false,
          data: old.data,
          total: old.total,
        }));
        loaderActions.dataLoading(false);
      });
  };

  const columns = [
    { field: 'id', headerName: 'Id' },
    {
      field: 'firstName',
      headerName: 'First Name',
      flex: 0.4,
    },
    {
      field: 'lastName',
      headerName: 'Surname',
      flex: 0.3,
    },
    { field: 'school', headerName: 'School', flex: 0.4 },
    { field: 'email', headerName: 'Email', flex: 0.5 },
    {
      field: 'jobTitle',
      headerName: 'Job Title',
      sortable: false,
      flex: 0.4,
      valueGetter: getStaffJobTitle,
    },
    {
      field: 'jobRole',
      headerName: 'Job Role',
      sortable: false,
      flex: 0.4,
      valueGetter: getStaffJobRole,
      cellClassName: (params) => {
        return clsx('filter-lib', {
          negative: params.value == null || params.value === '',
        });
      },
    },
    { field: 'accessLevel', headerName: 'Access', sortable: false, flex: 0.3 },
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      hideable: false,
      renderCell: (params) => {
        const handleUpdate = (e) => {
          let row = getSelectedRow(params);
          setStaffId(JSON.stringify(row.id, null, 4));
          setIsShowEditStaff(true);
        };

        const onDeleteClick = (e) => {
          e.stopPropagation();
          let row = getSelectedRow(params);
          let id = JSON.stringify(row.id, null, 4);
          setStaffId(id);
          setIsRemovePopupOpen(true);
        };

        const handleAccess = (e) => {
          e.stopPropagation();
          let row = getSelectedRow(params);
          setStaffId(JSON.stringify(row.id, null, 4));
          row.accessLevel !== null
            ? setIsShowRevokeAccess(true)
            : setIsShowGrantAccess(true);
        };
        return (
          <div>
            {/* {params.row.accessLevel !== null ? (
              params.row.accessType === 2 ? (
                <GridActionsCellItem
                  icon={<KeyIcon onClick={handleAccess} />}
                  label='LockOpen'
                />
              ) : (
                <GridActionsCellItem
                  icon={<VpnKeyOffIcon />}
                  label='Stop'
                  disabled
                />
              )
            ) : (
              <GridActionsCellItem
                icon={<LockIcon onClick={handleAccess} />}
                label='Lock'
              />
            )} */}
            <GridActionsCellItem
              icon={<EditIcon onClick={handleUpdate} />}
              label='Edit'
            />
            <GridActionsCellItem
              icon={<DeleteIcon onClick={onDeleteClick} />}
              label='Delete'
            />
          </div>
        );
      },
    },
  ];

  const handleClose = () => {
    setIsShowExcelUpload(false);
    setIsShowAddStaff(false);
    setIsShowEditStaff(false);
    setIsShowGrantAccess(false);
    setIsShowRevokeAccess(false);
  };

  const exportMatExcelTemplate = () => {
    matStaffService
      .exportMatExcelTemplate(matGroupId)
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'MAT Staff Template.xlsx');
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {});
  };

  const exportMatExcelStaff = () => {
    matStaffService
      .exportMatExcelStaff(matGroupId)
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
        );
        const link = document.createElement('a');
        link.href = url;
        if (storageService.getItem(WebStorageNames.UsRegion) === 'us') {
          link.setAttribute('download', 'Group Staff Data.xlsx');
        } else {
          link.setAttribute('download', 'MAT Staff Data.xlsx');
        }
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {});
  };

  return (
    <ActionPlanMainWrapper>
        <Box
          spacing={2}
          sx={{
            width: '100%',
            '& .filter-lib.negative': {
              backgroundColor: 'rgba(0,0,0, 0.1)',
            },

            flexGrow: 1,
          }}
        >
          <Grid container>
            {/* Sub Heading Grid */}
            <Grid Item lg={4} md={4} xs={12}>
              <Stack direction='row' justifyContent={'flex-start'}>
              
              </Stack>
            </Grid>

            <Grid item lg={8} md={8} xs={12}>
              <Stack direction='row' justifyContent={'flex-end'} spacing={2}>
                <Button
                  style={{
                    backgroundColor: '#007A7A',
                  }}
                  startIcon={<UploadIcon />}
                  variant='contained'
                  disableElevation
                  component='span'
                  onClick={() => {
                    setIsShowExcelUpload(true);
                  }}
                >
                  Upload Excel
                </Button>

                {pageState.total > 0 ? (
                  <Button
                    style={{
                      backgroundColor: '#E84F6B',
                    }}
                    variant='contained'
                    disableElevation
                    onClick={() => {
                      exportMatExcelStaff();
                    }}
                  >
                    Download Current Data
                  </Button>
                ) : null}

                {pageState.total < 1 ? (
                  <Button
                    style={{
                      backgroundColor: '#45338C',
                    }}
                    variant='contained'
                    disableElevation
                    onClick={() => {
                      exportMatExcelTemplate();
                    }}
                  >
                    Download Template
                  </Button>
                ) : null}
              </Stack>
            </Grid>
            {isUnVerifiedJobTitles && isShowInfoMsg ? (
              <Grid>
                <Alert onClose={handleInfoMsgClose} severity='info'>
                  One or more job titles have not been recognised within our
                  library. A member of our support team will contact you and
                  there is no action for you to take.
                </Alert>
              </Grid>
            ) : null}

            <Grid lg={12} md={12} xs={12} my='20px'>
              <Item>
                <Grid container>
                  <Grid item lg={6} md={6} xs={12} textAlign='left'>
                    <h2 style={{ margin: '0px' }}>Staff Members</h2>
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <Stack
                      direction='row'
                      alignItems='flex-end'
                      justifyContent='flex-end'
                      spacing={1}
                    >
                      <TextField
                        className='staff-search-txtfield'
                        size='small'
                        name='search'
                        id='search'
                        label='Search'
                        onChange={(e) => {
                          handleSearch(e.target.value);
                        }}
                      />
                      <FormControl
                        sx={{ minWidth: 200 }}
                        size='small'
                        className='staff-search-dd'
                      >
                        <InputLabel id='job-roleselect-label'>
                          Job Role
                        </InputLabel>
                        <Select
                          labelId='job-role-select-label'
                          id='jobRole'
                          value={jobRole}
                          label='Job Role'
                          onChange={handleJobRoleChange}
                        >
                          <MenuItem value={-1}>All</MenuItem>
                          <MenuItem value={0}>None</MenuItem>
                          <MenuItem value={4}>
                            Teaching Staff (Classroom)
                          </MenuItem>
                          <MenuItem value={8}>
                            Teaching Staff (Non-Classroom)
                          </MenuItem>
                          <MenuItem value={16}>
                            Support Staff (Classroom)
                          </MenuItem>
                          <MenuItem value={32}>
                            Support Staff (Non-Classroom)
                          </MenuItem>
                          <MenuItem value={64}>Middle Leader</MenuItem>
                          <MenuItem value={128}>Senior Leader</MenuItem>
                        </Select>
                      </FormControl>
                      <Tooltip title='Add New Staff Member' followCursor>
                        <IconButton
                          style={{
                            backgroundColor: '#FF5939',
                            color: '#ffffff',
                          }}
                          variant='contained'
                          aria-label='Add'
                          size='small'
                          onClick={() => {
                            setIsShowAddStaff(true);
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </Grid>

                  <Grid item lg={12} md={12} xs={12} mt='20px'>
                    <div style={{ height: 600, width: '100%' }}>
                      <HeaderDataGrid
                        rows={pageState.data}
                        rowCount={pageState.total}
                        loading={pageState.isLoading}
                        sortingMode='server'
                        onSortModelChange={handleSortModelChange}
                        rowsPerPageOptions={[10, 30, 50, 70, 100]}
                        pagination
                        page={pageState.page - 1}
                        pageSize={pageState.pageSize}
                        paginationMode='server'
                        onPageChange={(newPage) => {
                          setPageState((old) => ({
                            ...old,
                            page: newPage + 1,
                          }));
                        }}
                        onPageSizeChange={(newPageSize) =>
                          setPageState((old) => ({
                            ...old,
                            pageSize: newPageSize,
                          }))
                        }
                        rowHeight={40}
                        columns={columns}
                        disableColumnMenu
                        disableColumnFilter={true}
                        disableColumnSelector={true}
                        disableDensitySelector={true}
                        disableExportButton={true}
                        disableSelectionOnClick={true}
                        getRowClassName={(params) =>
                          params.indexRelativeToCurrentPage % 2 === 0
                            ? 'even'
                            : 'odd'
                        }
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                          setColumnVisibilityModel(newModel)
                        }
                        components={{
                          NoRowsOverlay: () => (
                            <Stack
                              height='100%'
                              alignItems='center'
                              justifyContent='center'
                            >
                              No record
                            </Stack>
                          ),
                          NoResultsOverlay: () => (
                            <Stack
                              height='100%'
                              alignItems='center'
                              justifyContent='center'
                            >
                              No record
                            </Stack>
                          ),
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Item>
            </Grid>
          </Grid>

          {isShowExcelUpload ? (
            <MatStaffExcel
              handleClose={handleClose}
              exportMatExcelTemplate={exportMatExcelTemplate}
              exportMatExcelStaff={exportMatExcelStaff}
              getMatStaff={getMatStaff}
              handleExcelUpload={handleExcelUpload}
            />
          ) : (
            <div></div>
          )}

          {isShowAddStaff ? (
            <AddStaffMember
              handleClose={handleClose}
              matSchools={matSchools}
              getMatStaff={getMatStaff}
              isMat={true}
              schoolId={''}
            />
          ) : (
            <div></div>
          )}

          {isShowEditStaff ? (
            <EditStaffMember
              handleClose={handleClose}
              staffId={staffId}
              matSchools={matSchools}
              getMatStaff={getMatStaff}
              isMat={true}
              schoolId={''}
            />
          ) : (
            <div></div>
          )}

          {isShowGrantAccess ? (
            <GrantAccess
              handleClose={handleClose}
              isMat={true}
              staffId={staffId}
              matSchools={matSchools}
              getMatStaff={getMatStaff}
            />
          ) : (
            <div></div>
          )}

          {isShowRevokeAccess ? (
            <RevokeAccess
              handleClose={handleClose}
              isMat={true}
              staffId={staffId}
              matSchools={matSchools}
              getMatStaff={getMatStaff}
            />
          ) : (
            <div></div>
          )}

          {isRemovePopupOpen ? (
            <ConfirmPopupForm
              isOpen={isRemovePopupOpen}
              onClose={() => {
                setIsRemovePopupOpen(false);
                setStaffId('');
              }}
              onOk={() => {
                handleDelete(staffId);
              }}
              successPopupMessage='Are you sure to delete this record?'
            />
          ) : (
            <div></div>
          )}
        </Box>
      
    </ActionPlanMainWrapper>
  );
};

const mapDispatchToProps = (dispatch) => ({
  loaderActions: bindActionCreators(loaderAction, dispatch),
  navigationActions: bindActionCreators(navigationActions, dispatch),
});

export default connect(null, mapDispatchToProps)(MatStaff);
