import React, { useState, useEffect } from 'react';
import { Grid, Button, Typography, Stack } from '@mui/material';
import { storageService } from '../../services';
import { PeopleMgtSwitch } from './PeopleMgtStyle';
import { useFetchAttendanceSettings } from '../../apis/SyncSettings/hooks/useFetchAttendanceSettings';
import { useSaveAttendanceSettings } from '../../apis/SyncSettings/hooks/useSaveAttendanceSettings';
import LoaderWrapper from '../../components/common/LoaderWrapper';
import { WebStorageNames } from '../../utils';

const attendanceFields = [
  { field: 'academicYear', label: 'Academic Year' },
  { field: 'authorisedAbsences', label: 'Authorised Absences' },
  { field: 'unAuthorisedAbsences', label: 'Unauthorised Absences' },
  { field: 'unExplainedAbsences', label: 'Unexplained Absences' },
  { field: 'possibleMarks', label: 'Possible Marks' },
  { field: 'missingMarks', label: 'Missing Marks' },
  { field: 'presentMarks', label: 'Present Marks' },
  { field: 'codePresent', label: 'Code: Present' },
  { field: 'codeA', label: 'Code: A' },
  { field: 'codeB', label: 'Code: B' },
  { field: 'codeC', label: 'Code: C' },
  { field: 'codeD', label: 'Code: D' },
  { field: 'codeE', label: 'Code: E' },
  { field: 'codeF', label: 'Code: F' },
  { field: 'codeG', label: 'Code: G' },
  { field: 'codeH', label: 'Code: H' },
  { field: 'codeI', label: 'Code: I' },
  { field: 'codeJ', label: 'Code: J' },
  { field: 'codeK', label: 'Code: K' },
  { field: 'codeL', label: 'Code: L' },
  { field: 'codeM', label: 'Code: M' },
  { field: 'codeN', label: 'Code: N' },
  { field: 'codeO', label: 'Code: O' },
  { field: 'codeP', label: 'Code: P' },
  { field: 'codeQ', label: 'Code: Q' },
  { field: 'codeR', label: 'Code: R' },
  { field: 'codeS', label: 'Code: S' },
  { field: 'codeT', label: 'Code: T' },
  { field: 'codeU', label: 'Code: U' },
  { field: 'codeV', label: 'Code: V' },
  { field: 'codeW', label: 'Code: W' },
  { field: 'codeX', label: 'Code: X' },
  { field: 'codeY', label: 'Code: Y' },
  { field: 'codeZ', label: 'Code: Z' },
];

function AttendanceSyncSetting() {
  const matGroupId = storageService.getItem(WebStorageNames.MatGroupId);
  const isMat = storageService.getItem(WebStorageNames.IsMat);
  const schoolId = storageService.getItem(WebStorageNames.SchoolId);

  const isGroupEnabled = isMat && !!matGroupId;
  const id = isGroupEnabled ? matGroupId : schoolId;

  const [toggles, setToggles] = useState({});

  const { data, isLoading } = useFetchAttendanceSettings(isGroupEnabled, id);
  const { mutate: saveSettings } = useSaveAttendanceSettings(isGroupEnabled);

  useEffect(() => {
    if (data) {
      const recordData = data[0];
      const filteredData = attendanceFields.reduce((acc, { field }) => {
        acc[field] = recordData[field] ?? false; 
        return acc;
      }, {});
      setToggles(filteredData);
    }
  }, [data]);

  const handleToggle = (field) => {
    setToggles((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const handleSave = () => {
    if (!id) return;

    const dataToSave = { ...toggles, GroupId: matGroupId, SchoolId: schoolId };
    saveSettings(dataToSave);
  };

  return (
    <LoaderWrapper isLoading={isLoading || saveSettings.isLoading}>
      <Grid container rowSpacing={2.5} mt={2}>
        {attendanceFields.map(({ field, label }) => (
          <Grid item xs={12} sm={4} md={3} lg={3} key={field}>
            <Stack direction="row" spacing={1} alignItems="center">
              <PeopleMgtSwitch
                checked={toggles[field]}
                onChange={() => handleToggle(field)}
              />
              <Typography variant="body2">{label}</Typography>
            </Stack>
          </Grid>
        ))}
        <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            <Button
              variant="outlined"
              className="save-btn"
              onClick={handleSave}
              disableRipple
            >
              Save
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </LoaderWrapper>
  );
}

export default AttendanceSyncSetting;

