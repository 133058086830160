import { useMutation, useQuery } from '@tanstack/react-query';
import { fetchStaffSettings, saveStaffSettings } from '../SyncSettingsService';
import { HandleApiToast } from '../../../components/common/HandleApiToast';

export const useStaffSyncSettings = ({ contextType, id }) => {
  const isGroup = contextType === 'group';


  const saveSettings = useMutation(
    (data) => saveStaffSettings(data, isGroup),
    {
      onError: (error) => HandleApiToast('error', error, 'Failed to save staff settings.'),
      retry: false,
    }
  );

  const syncSettings = useQuery(
    ['staffSettings', id, isGroup],
    () => fetchStaffSettings(id, isGroup),
    {
      enabled: !!id,
      onError: (error) => HandleApiToast('error', error, 'Failed to fetch staff settings.'),
      retry: false,
    }
  );

  return { saveSettings, syncSettings };
};
