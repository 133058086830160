
import { fetchStudentsWithSchoolsBySchoolId } from '../StudentService';
import { HandleApiToast } from '../../../components/common/HandleApiToast';
import { useQuery } from '@tanstack/react-query';

export const useFetchStudentWithSchoolBySchoolId = (schoolId,enabled = true) => {
  return useQuery(
    ['studentsWithSchoolsbySchoolId', schoolId], 
    () => fetchStudentsWithSchoolsBySchoolId(schoolId),
    {
      enabled:!!enabled &&  !!schoolId,
      onSuccess: (data) => {
        
      },
      onError: (error) => {
        HandleApiToast('error', error, 'Failed to fetch students.');
      },
    }
  );
};
