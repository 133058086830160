import { useQuery } from "@tanstack/react-query";
import { fetchAttendanceSettingsByGroupId, fetchAttendanceSettingsSchoolId } from "../SyncSettingsService";
import { HandleApiToast } from "../../../components/common/HandleApiToast";

export const useFetchAttendanceSettings = (isGroupEnabled, id, enabled = true) => {
    return useQuery(
      ['attendanceSettings', id],
      () =>
        isGroupEnabled
          ? fetchAttendanceSettingsByGroupId(id)
          : fetchAttendanceSettingsSchoolId(id),
      {
        enabled: !!enabled && !!id, // Only fetch if id is valid
        onError: (error) => {
          HandleApiToast('error', error, 'Failed to fetch attendance settings.');
        },
      }
    );
  };