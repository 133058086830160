import { useQuery } from "@tanstack/react-query";
import { HandleApiToast } from "../../../components/common/HandleApiToast";
import { fetchAchievementSettings } from "../SyncSettingsService";

export const useFetchAchievementSettings = (id, isGroup) => {
    return useQuery(
      ['achievementSettings', id, isGroup],
      () => fetchAchievementSettings(id, isGroup),
      {
        enabled: !!id,
        onError: (error) => HandleApiToast('error', error, 'Failed to fetch achievement settings.'),
      }
    );
  }; 