import React, { useState, useEffect } from 'react';
import { Grid, Button, Typography, Stack } from '@mui/material';
import { toast } from 'react-toastify';
import { storageService } from '../../services';
import { PeopleMgtSwitch } from './PeopleMgtStyle';
import { useStaffSyncSettings } from '../../apis/SyncSettings/hooks/useStaffSyncSettings';
import LoaderWrapper from '../../components/common/LoaderWrapper';
import { WebStorageNames } from '../../utils';
import { useSaveStaffSyncSetting } from '../../apis/SyncSettings/hooks/useSaveStaffSyncSetting';
import { HandleApiToast } from '../../components/common/HandleApiToast';

const validFields = [
  { field: 'isJobRoleSync', label: 'Job Role' },
  { field: 'isJobTitleSync', label: 'Job Title' },
  { field: 'isDepartmentSync', label: 'Department' },
  { field: 'isStartDateSync', label: 'Start Date' },
];

function StaffSyncSettings() {
  const groupId = storageService.getItem(WebStorageNames.MatGroupId);
  const isMat = storageService.getItem(WebStorageNames.IsMat);
  const schoolId = storageService.getItem(WebStorageNames.SchoolId);
  const isGroupContext = isMat && !!groupId;
  const id = isGroupContext ? groupId : schoolId;

  const {  syncSettings } = useStaffSyncSettings({
    contextType: isGroupContext ? 'group' : 'school',
    id: isGroupContext ? groupId : schoolId,
  });
 const { mutate: saveSettings } = useSaveStaffSyncSetting(isGroupContext);
  const [toggles, setToggles] = useState({});

  useEffect(() => {
    if (syncSettings?.data && syncSettings.data.length > 0) {
      const firstRecord = syncSettings?.data[0];
      const filteredData = validFields.reduce((acc, { field }) => {
        acc[field] = firstRecord[field] ?? false;
        return acc;
      }, {});
      setToggles(filteredData);
    }
  }, [syncSettings?.data]);

  const handleToggle = (field) => {
    setToggles((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const handleSave = () => {
     if (!id) {
       HandleApiToast('error', null, 'GroupId or SchoolId not found.');
       return;
     }
 
     const dataToSave = { ...toggles, GroupId: groupId, SchoolId: schoolId };
     saveSettings(dataToSave);
   };
 

  return (
    <LoaderWrapper isLoading={syncSettings.isLoading || saveSettings.isLoading}>
      <Grid container spacing={2.5} mt={2}>
        {validFields.map(({ field, label }) => (
          <Grid item xs={12} sm={4} md={3} key={field}>
            <Stack direction="row" spacing={1} alignItems="center">
              <PeopleMgtSwitch
                checked={toggles[field]}
                onChange={() => handleToggle(field)}
              />
              <Typography variant="body2">{label}</Typography>
            </Stack>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            <Button variant="outlined" onClick={handleSave} className="save-btn" disableRipple>
              Save
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </LoaderWrapper>
  );
}

export default StaffSyncSettings;

