import { useMutation } from '@tanstack/react-query';


import { HandleApiToast } from '../../../components/common/HandleApiToast';
import { saveAchievementSettings } from '../SyncSettingsService';
export const useSaveAchievementSettings = (isGroup) => {
  return useMutation((data) => saveAchievementSettings(data, isGroup), {
    onError: (error) =>
      HandleApiToast('error', error, 'Failed to save achievement settings.'),
  });
};
