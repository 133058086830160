import { useMutation } from '@tanstack/react-query';
import { saveStudentSettings } from '../SyncSettingsService';
import { HandleApiToast } from '../../../components/common/HandleApiToast';

export const useSaveStudentSettings = (isGroup) => {
  return useMutation((data) => saveStudentSettings(data, isGroup), {
    onError: (error) =>
      HandleApiToast('error', error, 'Failed to save student settings.'),
  });
};
